<template>
  <div>
    <div class="banner">
      <warning-tip code="TBRECOMMENDKEYS"></warning-tip>

      <main-top title="淘宝下拉词和长尾词推荐" desc="实时查询淘宝下拉词和长尾词推荐" spend="TBRECOMMENDKEYS">
        <!-- 使用搜索框组件 -->
        <InputView 
           :CanYouClick="CanYouClick" 
            placeholder-name="请输入关键词"
            @sendSearchName="getSearchName"></InputView>
      </main-top>

      <bordure-row title="" shadow style="margin-bottom: 30px">
        <!-- 以下部位为表格 -->
        <div>
          <div class="control-tool">
            <el-button type="primary" size="small" @click="downloadExcel"
              >一键导出</el-button
            >
          </div>

          <el-table :data="keys" style="width: 100%; border: 1px solid #f4f4f4" size="mini" 
          :header-cell-style="{
            background: '#f8f8f8',
            'font-family': 'PingFangSC-Regular',
            color: '#666666 !important',
            'letter-spacing': '0',
            'text-align': 'center',
            'font-weight': 'normal',
          }">
            
            <el-table-column align="center" prop="searchtext" label="关键词" >
            </el-table-column>
            
          </el-table>

        </div>
      </bordure-row>

      <!-- 讲解文案区域 -->
      <Introduce :introduce="introduce"></Introduce>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { tbRecommendKeys } from "../../request/api"; //这里是引入请求
import InputView from "../public/InputView";
import Introduce from "../public/Introduce";
export default {
  data() {
    return {
      value: "",
      CanYouClick: true, //搜索框是否允许点击
      keys: [
        {
          searchtext: "连衣裙2020新款夏",
        },
        {
          searchtext: "连衣裙2020新款夏A字裙",
        },
      ],

      total: 5, //表格总条数
      introduce: [
        {
          title: "功能介绍",
          icon: "&#xe60a;",
          content: [
            "实时查询淘宝下拉词_长尾关键词",
          ],
        },
      ], //介绍相关数组
    };
  },
  components: {
    InputView,
    Introduce,
  },
  methods: {
    // 点击搜索时的方法
    getSearchName(value) {
      this.value = value;
      this.$store.commit("alterJumpFlag", true);
      this.$store.commit(
        "alterLoadContent",
        "正在获取关键词的数据"
      );
      this.CanYouClick = false; //不允许再点击搜索
      this.tbRecommendKeysFn(value); //请求商品评价的数据
    },
    // 请求关键词
    tbRecommendKeysFn(value) {
      this.goodsFeedList = [];
      tbRecommendKeys({
        key: value,
      })
        .then((data) => {
          let res = data.data || {};
          this.keys = res.keys || [];
          this.total = Number(res.allCount);
        })
        .finally(() => {
          this.$store.commit("alterJumpFlag", false);
          this.CanYouClick = true; //允许再次搜索
        });
    },
    downloadExcel() {
      this.$confirm("确定下载列表文件?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.excelData = this.keys;
          this.export2Excel();
        })
        .catch(() => {});
    },
    export2Excel() {
      var that = this;
      require.ensure([], () => {
        const { export_json_to_excel } = require("@/excel/export2Excel"); // 这里必须使用绝对路径，使用@/+存放export2Excel的路径
        // 导出的表头名信息
        const tHeader = [
          "关键词",
        ];
        // 导出的表头字段名，需要导出表格字段名
        const filterVal = [
          "searchtext",
        ];

        const list = that.excelData;
        let data = list.map(function (item) {
          return filterVal.map(function (path) {
            let newVal = _.get(item, path);
            return newVal;
          });
        });
        let curDate = moment().format("YYYY-MM-DD");
        export_json_to_excel(tHeader, data, "淘宝下拉词-" + curDate); // 导出的表格名称，根据需要自己命名
      });
    },

  },
};
</script>

<style lang="less" scoped>
.bannerContent {
  display: flex;
}


.img_floor {
  width: 100px;
  height: 100px;
  background: linear-gradient(0deg, #ceefff, #7bd5ff);
  margin-right: 50px;
}

.li-icon {
  width: auto;
  height: 24px;
}

.li-pic {
  max-width: 160px;
  width: auto;
  height: 80px;
}

.control-tool {
  padding: 4px;
  text-align: right;
}

</style>